* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "Poppins Open Sans", -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family:"Be Vietnam Pro", sans-serif !important;
}

code {
  font-family: "Poppins Open Sans", source-code-pro, Menlo, Monaco, Consolas,
    "Courier New", monospace;
    font-family:"Be Vietnam Pro", sans-serif !important;
}